import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers/'
import '@ifca-root/react-component/src/assets/styles/app.scss'
import Layout from '@ifca-root/react-component/src/components/Layout/Layout'
import { createBrowserHistory } from 'history'
import React, { useEffect, useState, useReducer } from 'react'
import { Router } from 'react-router-dom'
import Routes from './Router/Routes'
import AppContext from './Store/AppContext'
import { GlobalInitialState, RootReducer } from './Store/RootReducer'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '@ifca-root/react-component/src/assets/theme'
import { ApolloProvider } from '@apollo/react-hooks'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { setAccessToken } from '../../AccessToken'
import {
  HRClient,
  hrNodeNavUrl,
  hrNodeNavUrlclear,
  hrNodeRefreshUrl,
  hrNodeUrl,
  WSclient,
} from 'HRClient'
import 'assets/style/app.scss'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import { ClearCacheProvider } from 'react-clear-cache'

export const history = createBrowserHistory()

const App = () => {
  const [globalState, dispatch] = useReducer(RootReducer, GlobalInitialState)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const loginuserid = window?.location?.href
      ?.split('?')
      ?.find(x => x.includes('userID'))
      ?.split('=')[1]
      ?.split('&')[0]

    const logintoken = window?.location?.href
      ?.split('?')
      ?.find(x => x.includes('token'))
      ?.split('=')[1]

    console.log(loginuserid, logintoken)

    if (
      !window?.location?.href?.split('?')?.find(x => x.includes('token')) ||
      window?.location?.href?.split('?')?.find(x => x.includes('mobile'))
    ) {
      fetch(hrNodeRefreshUrl, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          userID: loginuserid,
        }),
        headers: { 'Content-Type': 'application/json' },
      })
        .then(async x => {
          const { accessToken, user } = await x.json()
          setAccessToken(accessToken)
          setLoading(false)
          localStorage.setItem('loginInfo', JSON.stringify(user))
        })
        .catch(err => {
          console.error(err)
          setLoading(false)
          history.push('/authentication/404')
        })
    } else {
      let token = window?.location?.href
      ?.split('?')
      ?.find(x => x.includes('token'))
      ?.split('=')[1]

    if(!token){
      token= localStorage.getItem('refreshToken')
    }


    fetch(hrNodeNavUrl + `/${token}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(async responseToken => {
        const { refreshToken, accessToken, user } = await responseToken.json()

        localStorage.setItem('refreshToken', refreshToken)

        setAccessToken(accessToken)
        setLoading(false)
        localStorage.setItem('loginInfo', JSON.stringify(user))
      }).then(() => {
        setTimeout(() => {
          fetch(hrNodeNavUrlclear + `/${token}`, {
            method: 'GET',
            credentials: 'include',
          })
        }, 1500);
      

      } ) 
      .catch(err => {
        console.error(err)
        setLoading(false)
        history.push('/authentication/404')
      })
    }
  }, [])

  if (process.env.NODE_ENV !== 'development') console.log = () => {}

  return (
    <AppContext.Provider value={{ globalState, dispatch }}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={WSclient}>
          <ApolloProvider client={HRClient}>
            <ClearCacheProvider auto={true} filename="build.json">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Router history={history}>
                  <Layout loading={loading}>
                    {loading ? <Loading /> : <Routes />}
                  </Layout>
                  <SnackBarMsg
                    open={globalState?.Errors?.Error}
                    onClose={() =>
                      dispatch({
                        type: 'Errors',
                        payload: {
                          Error: false,
                          Message: '',
                        },
                      })
                    }
                    message={globalState?.Errors?.Message}
                  />
                </Router>
              </MuiPickersUtilsProvider>
            </ClearCacheProvider>
          </ApolloProvider>
        </ApolloProvider>
      </ThemeProvider>
    </AppContext.Provider>
  )
}
export default App
